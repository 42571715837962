import React, { useState } from 'react';
import gmail from '../../assets/images/contact/gmail.png'
import facebook from '../../assets/images/contact/facebook.png'
import linkedIn from '../../assets/images/contact/linkedin.png'
import Swal from 'sweetalert2';
import Thankyou from '../../assets/images/consultation/thankYou.gif';

const ContactUs = () => {
    const API_URL = process.env.REACT_APP_API_URL;

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.first_name) {
            newErrors.first_name = 'Please enter your first name';
        }
        if (!formData.last_name) {
            newErrors.last_name = 'Please enter your last name';
        }
        if (!formData.email) {
            newErrors.email = 'Please enter a valid email';
        }
        if (!formData.service) {
            newErrors.service = 'Please enter your message';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
        try {
            const response = await fetch(`${API_URL}/contact-us`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                Swal.fire({
                    imageUrl: Thankyou,
                    imageWidth: 150,
                    imageHeight: 150,
                    imageAlt: "Thank you gif",
                    text: "Your message has been sent.",
                    showConfirmButton: false,
                    timer: 3000,
                });
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    message: '',
                });
            } else {
                const errorData = await response.json();
                setErrors(errorData.errors || {});
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    return (
        <div className='flex flex-col sm:flex-row gap-10 justify-center items-center sm:pt-0 pt-10 sm:h-screen'>
            <div className='flex flex-col gap-5 px-5 sm:px-10 w-full'>
                <div>
                    <p className='text-[#B26B6B] sm:text-text-2xl text-lg font-semibold'>
                        Contact Us
                    </p>
                    <h1 className='font-semibold text-3xl sm:text-5xl'>
                        How can we assist you?
                    </h1>
                    <p className='sm:text-xl texl-sm mt-5'>
                        If you have more questions about our services, our team
                        of experts are ready to answer your inquiries.
                    </p>
                </div>
                <div className='mt-5 '>
                    <p className='text-[#B26B6B] sm:text-2xl texl-xl'>Or you can call our business lines and email us at <a href="https://prosynergy.ph/" target="_blank" className='underline underline-offset-8'>psi.rems@prosynergy.ph</a></p>
                    <ul className='flex flex-col w-full gap-2 text-base sm:text-xl my-5'>
                        <li>+63 917 102 5133</li>
                        <li>+63 917 157 3832</li>
                    </ul>
                    <ul className='flex flex-row w-full'>
                        <li className='p-1.5 rounded-lg text-center'>
                            <a href="mailto:psi.rems@prosynergy.ph" target="_blank" rel="noreferrer">
                                <img src={gmail} alt='gmail' className='w-[30px] h-[30px]'/>
                            </a>
                        </li>
                        <li className='p-1.5 rounded-lg text-center'>
                            <a href='https://www.linkedin.com/company/prosynergyinc/posts/?feedView=all' target="_blank" rel="noreferrer">
                                <img src={linkedIn} alt='linkedIn' className='w-[30px] h-[30px]'/>
                            </a>
                        </li>
                        <li className='p-1.5 rounded-lg text-center'> 
                            <a href='https://www.facebook.com/PSIQuezonAvenue' target="_blank" rel="noreferrer">
                                <img src={facebook} alt='facebook' className='w-[30px] h-[30px]'/>
                            </a>
                        </li>
                        {/* <li className='p-1.5 rounded-lg text-center'>
                            <a href='https://youtube.com/'>
                                <i className="fa-brands text-white fa-lg fa-youtube"></i>                                
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
            {/* <div className='flex flex-row gap-5 w-full px-5 md:px-0 lg:px-0 md:mt-10'>  */}
            <div className='flex gap-5 w-full px-5 md:px-0 lg:px-0 mt-10'>
                <div className='sm:border-2 sm:rounded-l-[50px] w-full sm:h-3/4'>
                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col sm:p-10 gap-5 w-full'>
                            <div className='flex sm:flex-row flex-col sm:gap-10 gap-5 w-full'>
                                <div className="w-full">
                                    <label htmlFor="first_name" className="block font-medium text-gray-700">First Name</label>
                                    <input
                                        type="text"
                                        id="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        className="form-input w-full border-gray-300 py-2 rounded-lg shadow-lg focus:ring-[#B26B6B] focus:border-[#B26B6B]"
                                    />
                                    {errors.first_name && (
                                        <p className="text-red-600">{errors.first_name}</p>
                                    )}
                                </div>
                                <div className="w-full">
                                    <label htmlFor="last_name" className="block font-medium text-gray-700">Last Name</label>
                                    <input
                                        type="text"
                                        id="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        className="form-input w-full border-gray-300 py-2 rounded-lg shadow-lg focus:ring-[#B26B6B] focus:border-[#B26B6B]"
                                    />
                                    {errors.last_name && (
                                        <p className="text-red-600">{errors.last_name}</p>
                                    )}
                                </div>
                            </div>
                            <div className="w-full">
                                <label htmlFor="email" className="block font-medium text-gray-700">Email Address</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="form-input w-full border-gray-300 py-2 rounded-lg shadow-lg focus:ring-[#B26B6B] focus:border-[#B26B6B]"
                                />
                                {errors.email && (
                                    <p className="text-red-600">{errors.email}</p>
                                )}
                            </div>
                            <div className="w-full">
                                <label htmlFor="message" className="block py-2 font-medium text-gray-700">What do you want to ask?</label>
                                <textarea
                                    id="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    className="form-input p-2 w-full border-gray-300 py-2 rounded-lg shadow-lg focus:ring-[#B26B6B] focus:border-[#B26B6B]"
                                    placeholder='Your message...'
                                    rows="4"
                                ></textarea>
                                {errors.message && (
                                    <p className="text-red-600">{errors.message}</p>
                                )}
                            </div>
                            <div className="flex items-center mb-2">
                                <input
                                    id="privacy"
                                    type="checkbox"
                                    required
                                    className="w-4 h-4 text-red-900 active:border-2 rounded focus:ring-white"
                                />
                                <label htmlFor="privacy" className="ms-2 text-xs font-medium text-gray-900">
                                    I have read and understood the <a href="/privacy-notice" className="text-[#277D3A] underline">Privacy Notice</a> of ProSynergy Inc.
                                </label>
                            </div>
                            <button 
                                type="submit"
                                className="text-white bg-red-800 hover:bg-red-900 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-base px-5 py-2.5 text-center me-2 mb-2"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactUs
