import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import LoginImage from '../../assets/images/login_image.png';
import Thankyou from '../../assets/images/consultation/thankYou.gif';

const ConsultationModal = ({ isOpen, onClose }) => {
    const API_URL = process.env.REACT_APP_API_URL;

    const thankYouCss = {
        color: "white",
        backgroundColor: "#214A21",
        padding: "0px 20px",
    };

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        service: '',
        platform: '',
        privacy: false,
        status: '0',
    });
    const [services, setServices] = useState([]);

    const [errors, setErrors] = useState({});
    const fetchServices = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/service`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setServices(response.data);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
    };

    useEffect(() => {
        fetchServices();
    }, []);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: type === 'checkbox' ? checked : value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: '',
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.first_name) {
            newErrors.first_name = 'Please enter your first name';
        }
        if (!formData.last_name) {
            newErrors.last_name = 'Please enter your last name';
        }
        if (!formData.email) {
            newErrors.email = 'Please enter a valid email';
        }
        if (!formData.service) {
            newErrors.service = 'Please select a service';
        }
        if (!formData.platform) {
            newErrors.platform = 'Please select how you found out about us';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const response = await fetch(`${API_URL}/consultation`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Unknown error');
            }

            const result = await response.json();
            console.log(result);

            // Reset form data and close modal
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                service: '',
                platform: '',
                privacy: false,
                status: '0'
            });
            onClose();

            Swal.fire({
                imageUrl: Thankyou,
                imageWidth: 150,
                imageHeight: 150,
                imageAlt: "Thank you gif",
                text: "We’ll be sending an email to you shortly to confirm your booking request with us. We look forward to making things happen for you!",
                showConfirmButton: true,
                confirmButtonText: 'Go to Dashboard',
                confirmButtonClass: thankYouCss,
                confirmButtonColor: '#214A21',
            });

        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                title: 'Error!',
                text: error.message,
                icon: 'error',
                showConfirmButton: true,
            });
        }
    };

    if (!isOpen) return null;

    const handleClose = () => {
        // Reset form data
        setFormData({
            first_name: '',
            last_name: '',
            email: '',
            service: '',
            platform: '',
        });
        // Call the passed onClose function
        onClose();
    };

    return (
        <div
            id="static-modal"
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center top-0 right-0 left-0 bg-black bg-opacity-50  p-4 overflow-y-auto overflow-x-hidden max-h-auto"
        >
            <div className='relative flex flex-col lg:flex-row w-full max-w-5xl max-h-full bg-white shadow-lg rounded-xl'>
                <img src={LoginImage} className='hidden lg:block w-1/2 object-cover rounded-tl-lg rounded-bl-lg' alt='shake hands' />
                <div className='flex flex-col w-full lg:w-1/2 p-2 overflow-y-auto overflow-x-hidden'>
                    <div className='flex justify-end'>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            onClick={handleClose}
                        >
                            <i className="fa-solid fa-lg fa-circle-xmark"></i>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className='flex flex-col w-full gap-2'>
                        <ul className='sm:px-6 px-2'>
                            <li className='font-semibold text-lg'>Need a consultation?</li>
                            <li className='text-sm py-2'>Talk to one of us to get things started. Enter the required details below, and we’ll reach out to you as soon we can.</li>
                            <li className='text-sm'>Consultations are FREE for the first 45 minutes. Additional time shall be charged.</li>
                        </ul>
                        <div className='flex flex-row gap-5 w-full px-6'>
                            <form className='w-full' onSubmit={handleSubmit}>
                                <div className="w-full mb-4">
                                    <label htmlFor="first_name" className="block text-xs font-medium text-gray-700">First Name</label>
                                    <input
                                        type="text"
                                        id="first_name"
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        className="form-input text-xs w-full border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 py-2.5 p-2"
                                        placeholder='Juan'
                                    />
                                    {errors.first_name && (
                                        <p className="text-xs text-red-600">{errors.first_name}</p>
                                    )}
                                </div>
                                <div className="w-full mb-4">
                                    <label htmlFor="last_name" className="block text-xs font-medium text-gray-700">Last Name</label>
                                    <input
                                        type="text"
                                        id="last_name"
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        className="form-input text-xs w-full border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 py-2.5 p-2"
                                        placeholder='Dela Cruz'
                                    />
                                    {errors.last_name && (
                                        <p className="text-xs text-red-600">{errors.last_name}</p>
                                    )}
                                </div>
                                <div className="w-full mb-4">
                                    <label htmlFor="email" className="block text-xs font-medium text-gray-700">Email Address</label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="form-input text-xs w-full border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 py-2.5 p-2"
                                        placeholder="prosynergy@email.com"
                                    />
                                    {errors.email && (
                                        <p className="text-xs text-red-600">{errors.email}</p>
                                    )}
                                </div>
                                <div className="w-full mb-4">
                                    <label htmlFor="service" className="block text-xs font-medium text-gray-700">Service to Avail/Inquire</label>
                                    <select
                                        id="service"
                                        value={formData.service}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    >
                                        <option value="" disabled>- Select a service -</option>
                                        {services.map(service => (
                                            <option key={service.id} value={service.id}>
                                                {service.service}
                                            </option>
                                        ))}
                                    </select>
                                    {errors.service && (
                                        <p className="text-xs text-red-600">{errors.service}</p>
                                    )}
                                </div>
                                <div className="w-full mb-4">
                                    <label htmlFor="platform" className="block text-xs font-medium text-gray-700">How did you find out about us?</label>
                                    <select
                                        id="platform"
                                        value={formData.platform}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    >
                                        <option value="" disabled>- Select a channel -</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="YouTube">YouTube</option>
                                        <option value="Friend’s Recommendation">Friend’s Recommendation</option>
                                    </select>
                                    {errors.platform && (
                                        <p className="text-xs text-red-600">{errors.platform}</p>
                                    )}
                                </div>

                                <div className="flex items-center mb-2">
                                    <input
                                        id="privacy"
                                        type="checkbox"
                                        checked={formData.privacy}
                                        onChange={handleChange}
                                        required
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                    />
                                    <label htmlFor="privacy" className="ms-2 text-xs font-medium text-gray-900">
                                        I have read and understood the <a href="/privacy-notice" className="text-[#277D3A] underline">Privacy Notice</a> of ProSynergy Inc.
                                    </label>
                                </div>
                                <button type="submit" className="text-white bg-[var(--customRed)] hover:bg-red-900 focus:ring-2 focus:outline-none focus:ring-red-300 rounded-full sm:text-lg px-5 py-0.5 w-full text-center my-2">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConsultationModal
