import React, { useState, useRef, useEffect } from 'react';
import Logo from '../../assets/images/logo_name.png';
import { useNavigate } from 'react-router-dom';
import { ucwords } from '../../utils/Ucwords';

const TopBar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [user, setUser] = useState(null);
    const dropdownRef = useRef(null);
    const dropdownButtonRef = useRef(null);
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;

    // const [isOpen, setIsOpen] = useState(false);

    // const toggleButton = () => {
    //     setIsOpen(!isOpen);
    // };

    useEffect(() => {
        try {
            const userData = localStorage.getItem('user');
            if (userData) {
                setUser(JSON.parse(userData));
            }
        } catch (error) {
            console.error('Error parsing user data:', error);
        }
    
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !dropdownButtonRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
    const closeDrawer = () => setIsDrawerOpen(false);
    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleLogout = async (e) => {
        e.preventDefault(); // Prevent the default anchor tag behavior

        try {
            const token = localStorage.getItem('token'); // Retrieve token from local storage
            const response = await fetch(`${API_URL}/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Attach token to headers
                },
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data.message); // Log success message
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                navigate('/login');
            } else {
                console.error('Logout failed:', response.statusText);
                // Optionally, show an alert or message to the user
            }
        } catch (error) {
            console.error('Error logging out:', error);
            // Optionally, show an alert or message to the user
        }
    };

    return (
        <>
            <nav className="fixed top-0 z-50 w-full border-b bg-green-800 border-green-700">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start">
                            <button 
                                className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2" 
                                type="button" 
                                data-drawer-target="drawer-navigation" 
                                data-drawer-toggle="drawer-navigation" 
                                aria-controls="drawer-navigation"
                                onClick={toggleDrawer}
                            >
                                <i className="fa-solid fa-xl fa-bars"></i>
                            </button>
                            <a href="/admin/dashboard" className="flex ml-2 md:mr-24">
                                <img src={Logo} alt='prosynergy logo' className='h-6 sm:h-8'/>
                            </a>
                        </div>
                        <div className="flex items-center">
                            <div className="text-white font-medium hidden sm:block">
                                {user ? `${ucwords(user.firstname)} ${ucwords(user.lastname)}` : 'Guest'}
                            </div>
                            <div className="flex items-center ml-3">
                                <button 
                                    type="button" 
                                    className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-green-600" 
                                    aria-expanded={isDropdownOpen} 
                                    onClick={toggleDropdown}
                                    ref={dropdownButtonRef}
                                >
                                    <span className="sr-only">Open user menu</span>
                                    <img className="w-8 h-8 rounded-full" src={user ? user.profilePicture : 'https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg'} alt="user photo" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            {isDrawerOpen && <div className="fixed inset-0 bg-black opacity-50 z-40" onClick={closeDrawer}></div>}
            <div id="drawer-navigation" className={`fixed top-[4.1rem] left-0 z-50 w-72 h-screen p-4 overflow-y-auto transition-transform ${isDrawerOpen ? 'translate-x-0' : '-translate-x-full'} bg-green-800`} tabIndex="-1" aria-labelledby="drawer-navigation-label">
                <div className="py-4 overflow-y-auto">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <a href="/admin/dashboard" className="flex items-center p-2 rounded-lg text-white hover:bg-green-700 group">
                                <i className="fa-solid w-9 bg-gray-300 text-green-700 p-2 rounded-lg text-center fa-info"></i>
                                <span className="ml-3">Inquiry</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/employee" className="flex items-center p-2 rounded-lg text-white hover:bg-green-700 group">
                                <i className="fa-solid bg-gray-300 text-green-700 p-2 rounded-lg w-9 text-center fa-users"></i>
                                <span className="ml-3">Employees</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/calendar" className="flex items-center p-2 rounded-lg text-white hover:bg-green-700 group">
                                <i className="fa-solid bg-gray-300 text-green-700 p-2 rounded-lg w-9 text-center fa-calendar"></i>
                                <span className="ml-3">Calendar</span>
                            </a>
                        </li>
                        <li>
                            <a href="/admin/hiring" className="flex items-center p-2 rounded-lg text-white hover:bg-green-700 group">
                                <i className="fa-solid bg-gray-300 text-green-700 p-2 rounded-lg w-9 text-center fa-user"></i>
                                <span className="ml-3">Hiring</span>
                            </a>

                        </li>
                        <li>
                            <a href="/admin/service" className="flex items-center p-2 rounded-lg text-white hover:bg-green-700 group">
                                <i className="fa-solid fa-bell-concierge bg-gray-300 text-green-700 p-2 rounded-lg w-9 text-center"></i>
                                <span className="ml-3">Services</span>
                            </a>
                        </li>
                        {/* <li>
                            <button
                                type="button"
                                className="flex items-center w-full p-2 text-base transition duration-75 rounded-lg group text-white hover:bg-green-700"
                                onClick={toggleButton}
                                aria-controls="dropdown-authorization"
                                aria-expanded={isOpen}
                            >
                                <i className="fa-solid fa-user-lock bg-gray-300 text-green-700 p-2 rounded-lg"></i>
                                <span className="flex-1 ml-3 text-left whitespace-nowrap">Authorizations</span>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4" />
                                </svg>
                            </button>
                            {isOpen && (
                                <ul id="dropdown-authorization" className="py-2 space-y-2">
                                    <li>
                                        <a href="/roles" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group text-white hover:bg-green-700">Role</a>
                                    </li>
                                    <li>
                                        <a href="/permissions" className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group text-white hover:bg-green-700">Permission</a>
                                    </li>
                                </ul>
                            )}
                        </li> */}
                    </ul>
                </div>
            </div>
            {isDropdownOpen && (
                <div 
                    ref={dropdownRef} 
                    className="fixed top-12 px-2 right-0 z-50 w-auto mt-2 rounded shadow-lg bg-green-700"
                    aria-labelledby="dropdown-button"
                >
                    <div className="px-2 py-2 border-b">
                        <p className="text-sm text-white">
                            {user ? `${ucwords(user.firstname)} ${ucwords(user.lastname)}` : 'Guest'}
                        </p>
                        <p className="text-sm font-medium text-white truncate">
                            {user ? `${user.email}` : ''}
                        </p>
                    </div>
                    <ul className="py-2">
                        <li>
                            <button className="w-full px-2 py-1 text-left text-sm text-green-300 hover:bg-green-600 hover:rounded">Profile</button>
                        </li>
                        <li>
                            <button 
                                onClick={handleLogout}
                                className="w-full px-2 py-1 text-sm text-left text-green-300 hover:bg-green-600 hover:rounded">
                                    Sign out
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
}

export default TopBar;
