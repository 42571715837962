import React, { useState } from 'react';
import parse from 'html-react-parser';

const Faq = () => {
    const [openIndexes, setOpenIndexes] = useState([]);

    const handleToggle = (index) => {
        setOpenIndexes(prevOpenIndexes => 
            prevOpenIndexes.includes(index) 
                ? prevOpenIndexes.filter(i => i !== index) 
                : [...prevOpenIndexes, index]
        );
    };

    const formatAnswer = (answer) => {
        const divRegex = /<div class="[^"]*">(.*?)<\/div>/g;
        return answer.replace(divRegex, '$1'); // Replace divs with their content
      };
      
    const faqs = [
        { 
            question: "1. What is the main focus of ProSynergy Inc.?", 
            answer: formatAnswer(`
                Here in ProSynergy Inc., we get things done! We are a trusted and visionary company, dedicated to providing exceptional resources and services to the banking and finance sectors. ProSynergy Inc. is committed to delivering outstanding service, with a focus on responsiveness to our clients' needs. 
                <br>
                <br>
                We offer a comprehensive range of services, including real estate tax payment, title verification, property valuation, transfer of ownership, and more. Our dedication to excellence and client satisfaction makes us a reliable partner in the industry.
            `)
        },

        { 
            question: "2. What is the cost of your service fee?", 
            answer: formatAnswer(`
                Our service fee for Real Estate Property Tax Payment is US$120. This excludes the assessment and other government fees which we will share with you once secured. Our service fee for Property Valuation is US$500 per property. Our service fee for Title Verification is US$350 per property. Our service fee for Transfer of Ownership is US$1,450 per property.
            `)        
        },

        { 
            question: "3. What are the inclusions of your service rate?", 
            answer: formatAnswer(`
                <div class="mb-5">
                    <div>
                        The inclusion are of the service rate for Real Estate Property Tax Payment are:
                    </div>
                    <ul class="pl-6 space-y-1">
                        <li>a. Assessment</li>
                        <li>b. Payment / Government Fee (RPT) dues</li>
                    </ul>
                </div>

                <div class="mb-5">
                    <div>The inclusion are of the service rate for Property Valuation are:</div>
                    <ul class="pl-6 space-y-1">
                        <li>a. Securing CTC of Title & TD</li>
                        <li>b. Endorse to appraiser for schedule</li>
                        <li>c. Appraisal confirmed schedule</li>
                        <li>d. Received appraisal report</li>
                    </ul>
                </div>

                <div class="mb-5">
                    <div>The inclusion are of the service rate for Title Verification are:</div>
                    <ul class="pl-6 space-y-1">
                        <li>a. Securing CTC w/ payment</li>
                        <li>b. Official report</li>
                    </ul>
                </div>

                <div>
                    <div>The inclusion are of the service rate for Transfer of Ownership are:</div>
                    <ul class="pl-6 space-y-1">
                        <li>a. Completion of Documents</li>
                        <li>b. BIR Assessment/BIR Payment</li>
                        <li>c. E-CAR application (filing)</li>
                        <li>d. Transfer Tax payment</li>
                        <li>e. RD assessment & payment</li>
                        <li>f. Assessor's office (TD transfer)</li>
                    </ul>
                </div>
            `)
        },

        { 
            question: "4. What are the documentary requirements?", 
            answer: formatAnswer(`          
                <div class="mb-5">
                    <div>The requirements to facilitate your request for Real Estate Property Tax Payment are:</div>
                    <ul class="pl-6 space-y-1">
                        <li>a. Previous RPT receipts or copy of Tax Declaration (TD)</li>
                        <li>b. Authorization Letter / SPA with valid IDs and signature</li>
                    </ul>
                </div>

                <div class="mb-5">
                    <div>The requirements to facilitate your request for Property Valuation are:</div>
                    <ul class="pl-6 space-y-1">
                        <li>a. Authorization letter / SPA with valid IDs and signature</li>
                        <li>b. Copy of title, tax declaration</li>
                        <li>c. Photo of the property</li>
                        <li>d. Lot plan</li>
                    </ul>
                </div>

                <div class="mb-5">
                    <div>The requirements to facilitate your request for Title Verification are:</div>
                    <ul class="pl-6 space-y-1">
                        <li>a. Authorization letter / SPA with valid IDs and signature</li>
                        <li>b. Copy of title</li>
                    </ul>
                </div>

                <div>
                    <div>The requirements to facilitate your request for Transfer of Ownership are:</div>
                    <ul class="pl-6 space-y-1">
                        <li>a. Notarized Deed of Sale</li>
                        <li>b. Tax Identification Number and ID of the buyer and the seller</li>
                        <li>c. Transfer Certificate of Title for a house or a lot</li>
                        <li>d. Condominium Certificate of Title (for condominiums)</li>
                        <li>e. Tax Clearance</li>
                        <li>f. Tax Declaration</li>
                        <li>g. Real Property Tax for the current year</li>
                        <li>h. Management certificate from the condominium admin</li>
                        <li>i. Certificate of non-tenancy from the condominium admin</li>
                        <li>j. Photo of the property for sale</li>
                        <li>k. Sworn Declaration of No Improvements to show that the sold property hasn’t incurred any improvements</li>
                        <li>l. Special Power of Attorney (SPA) if the person signing is not the owner indicated in the Deed of Sale</li>
                        <li>m. Certificate of the Philippine Consulate if the SPA was executed abroad</li>
                        <li>n. Marriage Certificate, Birth Certificate, and Certificate of No Marriage</li>
                        <li>o. Vicinity map or location map of the property</li>
                        <li>p. other documents required by the Register of Deeds, like consolidation of ownership or mortgage settlement</li>
                    </ul>
                </div>
            `)
        },

        { 
            question: "5. What is your estimated timeline?", 
            answer: formatAnswer(`
                For request for Real Estate Property Tax Payment, our estimated service timeline is 1-10 business days from receipt of the complete documents and downpayment. For Property Valuation, our estimated service timeline is 10-15 business days from receipt of the complete documents and downpayment. For Title Verification, our estimated service timeline is 7-10 business days from receipt of the complete documents and downpayment. For Transfer of Ownership, our estimated service timeline is 6 months to 1 year from receipt of the complete documents and downpayment.  
            `)
        },

        { 
            question: "6. How do I submit the required documents?", 
            answer: formatAnswer(`
                You may send us scanned copies of your documents to our email, psi.rems@prosynergy.ph or through a courier of your choice in the US (e.g. DHL, UPS, etc.). Our address is 1468 Zenith BLDG, ProSynergy Office, Quezon Ave, Quezon City.
            `)
        },

        { 
            question: "7. Will you be able to pick up the needed documents at my representative's location?", 
            answer: formatAnswer(`
                No, we don’t do document pick-ups. You can send your documents to our main office or any PSI branch near you.
            `)
        },

        { 
            question: "8. What are your payment options?", 
            answer: formatAnswer(`
                We accept Credit/Debit Cards, E-Wallets (GCash, Maya, GrabPay), Bank Transfers (Bank transfer-CBC, RCBC, BPI, & Unionbank) &  Remittance Centers (Billease & 7/11)
                
            `)
        },

        { 
            question: "9. Do you provide an installment payment plan?", 
            answer: formatAnswer(`
                Yes, we provide installment plans. 50% downpayment of the service fee, and the remaining balance is paid upon completion. However, the assessment fee from the government needs to be fully paid, for us to complete the RPT payment.
            `)
        },

        { 
            question: "10. Do you issue receipts for service fees?", 
            answer: formatAnswer(`
                Yes, service fees are covered by receipts.
            `)
        },

        { 
            question: "11. Do you serve clients nationwide?", 
            answer: formatAnswer(`
                Yes, we service clients nationwide in the Philippines.            
            `)
        },

        { 
            question: "12. Where are your branches located?", 
            answer: formatAnswer(`
                Besides our Metro Manila head office in Quezon City, we have branches in Dagupan, Cebu, Davao, Cagayan De Oro and Tuguegarao.
            `)
        },

        { 
            question: "13. How do you keep stored documents safe?", 
            answer: formatAnswer(`
                We store soft copies in hard drives and we safe-keep hard copies in our secured vault.
            `)
        },

        {
            question: "14. Can you explain the step-by-step process for my Real Estate Property Tax request?",
            answer: formatAnswer(`
                <div className="mb-5">
                    <div>For Real Estate Property Tax Payment:</div>
                    <div>
                        We handle document assessment with the relevant government agencies. The assessment will be sent to you via email for your payment confirmation. Once you confirm and make the payment, we will proceed to pay the RPT dues. After obtaining the RPT receipt from the treasurer's office, we will inform you of the completion and send you a scanned copy of the receipt. Finally, we will issue the billing for the remaining service fee balance.
                    </div>
                </div>
    
                <div className="mb-5">
                    <div>For Property Valuation:</div>
                    <div>
                        The process begins by scheduling an ocular visit to the location of the property, our appraiser will evaluate the property. After the ocular visit and evaluation, our appraiser will come up with an appraisal report. Once Completed we will inform you and issue the billing for the remaining service fee balance. After your payment, the soft copy of the appraisal report will be sent via email and the hard copy will be shipped to your end or to an authorized representative.
                    </div>
                </div>
    
                <div className="mb-5">
                    <div>For Title Verification:</div>
                    <div>
                        We will first get a certified true copy of the latest title of your property, and we will include 2 tracebacks or more depending on your request and we will prepare a Title Verification Report pertaining to the history of the property you want us to verify. Once Completed we will inform you and issue the billing for the remaining service fee balance. After your payment, the soft copy of the appraisal report will be sent via email and the hard copy will be shipped to your end or to an authorized representative.
                    </div>
                </div>
    
                <div>
                    <div>For Transfer of Ownership:</div>
                    <ol className="list-decimal ml-6 mt-2">
                        <li>
                            Completion of documents and requirements. Upon completion, we will proceed to the Bureau of Internal Revenue (BIR) for the assessment of Capital Gains Tax, Documentary Stamp Tax (DST), and Certification Fee (CF). Then, we will email all the assessments for your confirmation and approval of payment, as well as the payment channel link. Upon payment confirmation, we will proceed to the bank to pay all the government taxes.
                        </li>
                        <li>
                            Next, we will go to the BIR to file the electronic Certificate Authorizing Registration (e-CAR) application and await the release of the e-CAR. Proceed to the Transfer Tax assessment. Upon assessment, it will be emailed to you for your confirmation and approval of payment, as well as the payment channel link. Upon payment confirmation, we will proceed to pay the Transfer Tax at the Treasurer’s office (municipality).
                        </li>
                        <li>
                            Upon the release of the e-CAR, we will proceed to the Registry of Deeds for assessment (following the same process as mentioned above), then for payment to secure the receipt and the electronic Primary Entry Book (EPEB) to monitor the status on LRA online tracking. We will await the release of the Title.
                        </li>
                    </ol>
                </div>
            `)
        },
        
        { 
            question: "15. Do you offer document safekeeping services?", 
            answer: formatAnswer(`
                Yes, we offer document safekeeping services.
            `)
        },

        { 
            question: "16. Do you pay a facilitation fee, and is it documented with a receipt?", 
            answer: formatAnswer(`
                Yes, to some government agencies but this will not be covered by receipts.
            `)
        },

        { 
            question: "17. How do you ensure that client information is protected?", 
            answer: formatAnswer(`
                PSI follows strict client information protocols. Our employees are not allowed to share any information to any unauthorized persons.
            `)
        },

        { 
            question: "18. How do you compute for the capital gains?", 
            answer: formatAnswer(`
                If the real estate is a capital asset, the purchase is subject to capital gains tax (CGT) of 6% of the gross selling price or current fair market value, whichever is higher, and documentary stamp tax (DST) of 1.5% of the actual consideration for the sale.
            `)
        },
    ];
    

    return (
        <div className='flex flex-col justify-center items-center w-full py-20 bg-[#FAFAFC] px-5'>
            <h1 className='font-bold text-2xl sm:text-[45px]'>Frequently Asked Questions</h1>

            {faqs.map((faq, index) => (
                <div key={index} className='lg:w-[1189px] w-full mt-6 border border-gray-200 shadow-lg rounded-xl'>
                    <h2>
                        <button 
                            type="button" 
                            className="flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 gap-3" 
                            onClick={() => handleToggle(index)} 
                            aria-expanded={openIndexes.includes(index)} 
                        >
                            <span className={`font-semibold ${openIndexes.includes(index) ? 'text-[#4F6E54]' : 'text-green-900'}`}>{faq.question}</span>
                            <i 
                                className={`w-3 h-3 fa-solid ${openIndexes.includes(index) ? 'fa-chevron-down' : 'fa-chevron-right'}`} 
                            ></i>
                        </button>
                    </h2>
                    <div 
                        className={`${openIndexes.includes(index) ? 'block' : 'hidden'}`}>
                        <div className="p-5">
                            <div className="mb-2 text-gray-500">{parse(faq.answer)}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Faq;
