import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ucwords } from '../../utils/Ucwords';
import Swal from 'sweetalert2';

const CreateScheduleModal = ({ isOpen, onClose, selectedDate }) => {
    const API_URL = process.env.REACT_APP_API_URL;

    const [errors, setErrors] = useState({});
    const [clients, setClients] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null); 

    const [formData, setFormData] = useState({
        client_id: '',
        user_id: '',
        schedule_date: selectedDate || '',
        schedule_time: '',
        sched_status: '1', // scheduled
        zoom: ''
    });

    useEffect(() => {
        fetchClients();
        fetchAccountOfficer();
    }, []);

    useEffect(() => {
        setFormData(prev => ({ ...prev, schedule_date: selectedDate || '' }));
    }, [selectedDate]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.client_id) newErrors.client_id = 'Please select client name';
        if (!formData.user_id) newErrors.user_id = 'Please select account officer';
        if (!formData.zoom) newErrors.zoom = 'Please enter Zoom link';
        return newErrors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'client_id') {
            const numericValue = Number(value);
            const client = clients.find(c => c.id === numericValue);
            
            console.log(client ? client.id : 'Client not found');
            console.log(value);
            
            setSelectedClient(client || null);
        }

        if (name === 'user_id') {
            const employee = employees.find(emp => emp.id === value);
            setSelectedEmployee(employee || null);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        const token = localStorage.getItem('token');
        if (!token) {
            console.error('No token found. Please log in.');
            return;
        }

        try {
            const response = await axios.post(`${API_URL}/schedules`, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });

            // response.data

            Swal.fire({
                title: 'Success!',
                text: 'Schedule created successfully!',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000            
            }).then(() => {
                window.location.reload();
            });

            handleClose();
        } catch (error) {
            console.error('Error submitting form:', error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an error creating the schedule.',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    };

    const fetchClients = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/consultation`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error);
        }
    };

    const fetchAccountOfficer = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/schedules/ao`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setEmployees(response.data);
        } catch (error) {
            console.error('Error fetching employees:', error);
        }
    };

    const handleClose = () => {
        setFormData({
            client_id: '',
            user_id: '',
            schedule_date: selectedDate || '', 
            schedule_time: '',
            sched_status: '1',
            zoom: ''
        });
        setSelectedClient(null);
        setSelectedEmployee(null);
        setErrors({});
        onClose();
    };

    return (
        <div 
            id="static-modal" 
            className={`fixed overflow-y-auto overflow-x-hidden inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 max-h-full ${isOpen ? 'flex' : 'hidden'}`} 
            data-modal-backdrop="static" 
            tabIndex="-1" 
            aria-hidden={!isOpen}
        >
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900" id="modal-title">
                            Create Schedule
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            onClick={handleClose}
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <form onSubmit={handleSubmit}>
                            <div className='flex flex-col gap-5 w-full'>
                                <div className='flex flex-col w-full gap-3'>
                                    <div className="w-full">
                                        <label htmlFor="client_id" className="block font-medium text-gray-700">Client Name</label>
                                        <select
                                            id="client_id"
                                            name="client_id"
                                            value={formData.client_id}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                        >
                                            <option value="">Select Client Name</option>
                                            {clients.map((client) => 
                                                <option key={client.id} value={client.id}>
                                                    {`${ucwords(client.firstname)} ${ucwords(client.lastname)}`}
                                                </option>
                                            )}
                                        </select>
                                        {errors.client_id && (
                                            <p className="text-red-600">{errors.client_id}</p>
                                        )}
                                    </div>

                                    <div className="w-full">
                                        <label htmlFor="inquiry" className="block font-medium text-gray-700">Inquiry</label>
                                        <input
                                            type="text"
                                            id="inquiry"
                                            value={selectedClient ? ucwords(selectedClient.service) : ''} 
                                            className="bg-gray-200 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-none focus:border-none"
                                            readOnly
                                        />
                                    </div> 

                                    <div className="w-full mt-3">
                                        <label htmlFor="user_id" className="block font-medium text-gray-700">Account Officer</label>
                                        <select
                                            id="user_id"
                                            name="user_id"
                                            value={formData.user_id}
                                            onChange={handleChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                        >
                                            <option value="">Select Account Officer</option>
                                            {employees.map((employee) => (
                                                <option key={employee.id} value={employee.id}>
                                                    {ucwords(employee.firstname)} {ucwords(employee.lastname)}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.user_id && (
                                            <p className="text-red-600">{errors.user_id}</p>
                                        )}
                                    </div>

                                    <div className="flex gap-4 mt-3">
                                        <div className="w-1/2">
                                            <label htmlFor="schedule_date" className="block font-medium text-gray-700">Schedule Date</label>
                                            <input
                                                type="date"
                                                id="schedule_date"
                                                name="schedule_date"
                                                value={formData.schedule_date || ''} 
                                                onChange={handleChange}
                                                className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                                required
                                                readOnly
                                            />
                                        </div>

                                        <div className="w-1/2">
                                            <label htmlFor="schedule_time" className="block font-medium text-gray-700">Schedule Time</label>
                                            <input
                                                type="time"
                                                id="schedule_time"
                                                name="schedule_time"
                                                value={formData.schedule_time}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="my-4">
                                        <label htmlFor="zoom" className="block font-medium text-gray-700">Zoom Link</label>
                                        <textarea
                                            id="zoom"
                                            name="zoom"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                            rows="3"
                                            value={formData.zoom}
                                            onChange={handleChange}
                                        ></textarea>
                                        {errors.zoom && (
                                            <p className="text-red-600">{errors.zoom}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center p-2 border-t border-gray-200 rounded-b gap-5">
                                <button
                                    type="submit"
                                    className="bg-green-500 hover:bg-green-600 text-white rounded-lg px-4 py-2"
                                >
                                    Create Schedule
                                </button>
                                <button
                                    type="button"
                                    className="text-gray-500 border border-gray-300 hover:bg-gray-100 rounded-lg px-4 py-2 mr-3"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateScheduleModal;
