import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick

import TopBar from '../../components/admin/TopBar';
import CreateScheduleModal from '../../components/admin/CreateScheduleModal';
import EditScheduleModal from '../../components/admin/EditScheduleModal';

import axios from 'axios';
import { ucwords } from '../../utils/Ucwords';

const CalendarPage = () => {
    const API_URL = process.env.REACT_APP_API_URL;

    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [events, setEvents] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [milestones, setMilestones] = useState(null);

    const openCreateModal = () => setIsCreateModalOpen(true);
    const closeCreateModal = () => setIsCreateModalOpen(false);

    const closeEditModal = () => {
        setSelectedSchedule(null);
        setMilestones(null);
        setIsEditModalOpen(false);
    };

    const handleDateClick = (arg) => {
        console.log(arg);
        setModalData({ selectedDate: arg.dateStr }); 
        openCreateModal(true);
    };
    
    const handleEventClick = async (info) => {
        const { extendedProps } = info.event;
        const schedule_id = extendedProps.s_id; 
        const id = Number(extendedProps.service_id); 
    
        if (!schedule_id || !id) {
            console.warn('No schedule_id or service_id found in event extendedProps');
            return;
        }
    
        try {
            const token = localStorage.getItem('token');
    
            const scheduleResponse = await axios.get(`${API_URL}/schedules/${schedule_id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Fetching milestones for ID:', id);

            const milestonesResponse = await axios.get(`${API_URL}/milestone/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
    
        // Set both schedule and milestones data together
        setSelectedSchedule(scheduleResponse.data);
        setMilestones(milestonesResponse.data);
        setIsEditModalOpen(true);
            console.log(scheduleResponse.data);
            
        } catch (error) {
            console.error('Error fetching data:', error.response ? error.response.data : error.message);
        }
    };
    
    
    const fetchSchedules = async () => {
        const token = localStorage.getItem('token'); // Or however you're storing your token
        try {
            const response = await axios.get(`${API_URL}/schedules`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            const formattedEvents = response.data.map(schedule => {
                let color; 

                if (schedule.sched_status === '0' || schedule.sched_status === 0) {
                    color = '#FFA500'; // Orange
                } else if (schedule.sched_status === '1' || schedule.sched_status === 1) {
                    color = '#0000FF'; // Blue
                } else if (schedule.sched_status === '2' || schedule.sched_status === 2) {
                    color = '#008000'; // Green
                } else if (schedule.sched_status === '3' || schedule.sched_status === 3) {
                    color = '#800080'; // Violet
                } else if (schedule.sched_status === '4' || schedule.sched_status === 4) {
                    color = '#FF0000'; // Red
                }
                
                // Ensure the returned object includes the color property
                return {
                    color: color,
                    title: `${ucwords(schedule.firstname)}`,
                    start: new Date(schedule.schedule_date),
                    end: new Date(schedule.schedule_date),
                    extendedProps: { 
                        s_id: schedule.id,
                        service_id: schedule.service_id,
                    },
                };
                
            });
            
            
            setEvents(formattedEvents);
    
        } catch (error) { 
            console.error('Error fetching schedules:', error);
        }
    };
    
    useEffect(() => {
        fetchSchedules();
    }, []);
    
    return (
        <>
            <TopBar />
            <CreateScheduleModal isOpen={isCreateModalOpen} onClose={closeCreateModal} selectedDate={modalData?.selectedDate}  />
            <EditScheduleModal 
                isOpen={isEditModalOpen} 
                onClose={closeEditModal}
                onSuccess={fetchSchedules} 
                data={selectedSchedule} 
                milestones={milestones}
            />
            <div className="p-5 mt-16 h-screen">
                <div className="flex pb-2 justify-between border-b-2">
                    <div className="font-semibold text-xl">
                        <label htmlFor="">My Calendar</label>
                    </div>
                </div>
                <div className='py-4'>
                    <FullCalendar
                        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        events={events}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                    />
                </div>
            </div>
        </>
    );
};

export default CalendarPage;
