import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const EditMilestoneModal = ({ isOpen, onClose, onSuccess, milestone }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        name: '',
        percentage: '',
    });

    const [initialData, setInitialData] = useState({
        name: '',
        percentage: '',
    });

    useEffect(() => {
        if (milestone) {
            const { name, percentage } = milestone;
            setFormData({
                name: name,
                percentage: percentage
            });
            setInitialData({
                name: name,
                percentage: percentage
            });
        }
    }, [milestone]);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Check if the form data has changed
        if (JSON.stringify(formData) === JSON.stringify(initialData)) {
            handleClose();
    
            Swal.fire({
                title: 'No Changes Detected',
                text: 'You have not made any changes to the data.',
                icon: 'info',
                showConfirmButton: false,
                timer: 3000
            });
            return;
        }
    
        try {
            // Retrieve token from local storage
            const token = localStorage.getItem('token');
    
            const response = await axios.put(
                `${API_URL}/milestones/${milestone.id}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${token}` // Attach token to headers
                    }
                }
            );
    
            const result = response.data;
    
            onSuccess();
            handleClose();
    
            Swal.fire({
                title: 'Success!',
                text: result.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            });
        } catch (error) {
            console.error('Error updating milestone:', error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an error updating the milestone.',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
            });
        }
    };
    
    
    const handleClose = () => {
        setFormData({
            name: '',
            percentage: '',
        });
        onClose();
    };
    return (
        <>
            <div id="static-modal" className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 ${isOpen ? 'flex' : 'hidden'}`} data-modal-backdrop="static" tabIndex="-1" aria-hidden="true">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-xl font-semibold text-gray-900">
                                Edit Milestone Modal
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                                onClick={handleClose}
                            >
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4">
                            <form 
                                onSubmit={handleSubmit}
                            >
                                <div className="mb-4 w-full">
                                    <label 
                                        htmlFor="name" 
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Name
                                    </label>
                                    <input 
                                        type="text" 
                                        id="name" 
                                        value={formData.name} 
                                        onChange={handleChange} 
                                        className="form-input mt-1 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800" 
                                        required
                                    />
                                </div>
                                <div className="mb-4 w-full">
                                    <label 
                                        htmlFor="percentage" 
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Percentage
                                    </label>                                    
                                    <input 
                                        type="number" 
                                        id="percentage" 
                                        value={formData.percentage} 
                                        onChange={handleChange} 
                                        className="form-input mt-1 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800" 
                                        placeholder='Number only'
                                        required
                                    />                           
                                </div>  
                                <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
                                    <button type="submit" className="text-white bg-[var(--customRed)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                                    <button type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-100" 
                                    onClick={handleClose}
                                    >Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditMilestoneModal
