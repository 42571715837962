import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

const AssignRoleModal = ({ isOpen, onClose, onSuccess, employee }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [formData, setFormData] = useState({
        role_id: '', 
    });

    const [isRole, setIsRole] = useState([]); 
    // Fetch current employee role
    const fetchEmployeeRole = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/roles/${employee}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.role_id) {
                setFormData({ role_id: response.data.role_id });  // Set current role_id
            }
        } catch (error) {
            console.error('Error fetching employee role:', error);
        }
    };

    // Fetch all available roles
    const fetchRole = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/roles`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setIsRole(response.data); // Set available roles
        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };

    const handleClose = () => {
        setFormData({ role_id: '' });
        onClose();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            await axios.put(`${API_URL}/roles/${employee}`, formData, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            Swal.fire({
                title: 'Success!',
                text: 'Role assigned successfully!',
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                window.location.reload();
            });
            onSuccess();
            handleClose();
        } catch (error) {
            console.error('Error updating role:', error);
            Swal.fire('Error!', 'Failed to assign role.', 'error');
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    useEffect(() => {
        if (employee) {
            fetchEmployeeRole();
            fetchRole();
        }
    }, [employee]);

    return (
        <div id="assign_role" className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-auto overflow-x-hidden max-h-full  ${isOpen ? 'flex' : 'hidden'}`} data-modal-backdrop="static" tabIndex="-1" aria-hidden="true">
            <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900">Assign Role Modal</h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            onClick={handleClose}
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <form onSubmit={handleSubmit}>
                            <div className="w-full">
                                <label htmlFor="role_id" className="block font-medium text-gray-700 mb-2">Role</label>
                                <select
                                    id="role_id"
                                    name="role_id"
                                    value={formData.role_id} // Ensure selected role is displayed
                                    onChange={handleChange} 
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                >
                                    <option value="">Select a role</option> {/* Default option */}
                                    {isRole.map((role) => (
                                        <option key={role.id} value={role.id}>
                                            {role.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b">
                                <button type="submit" className="text-white bg-[var(--customRed)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Update</button>
                                <button type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-100" onClick={handleClose}>Close</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssignRoleModal;
