import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { ucwords } from '../../utils/Ucwords';

const EditScheduleModal = ({ isOpen, onClose, onSuccess, data, milestones }) => { 
    const API_URL = process.env.REACT_APP_API_URL;

    const [displayData, setDisplayData] = useState({
        client_name: '',
        inquiry: '',
        user_id: '',
        schedule_date: '',
        schedule_time: '',
    });

    const [formData, setFormData] = useState({
        sched_status: '',
        client_id: '',
        email: '',
        milestone_id: ''

    });
    
    useEffect(() => {
        if (data) {
            const scheduleDate = new Date(data.schedule_date);
            const year = scheduleDate.getFullYear();
            const month = String(scheduleDate.getMonth() + 1).padStart(2, '0');
            const day = String(scheduleDate.getDate()).padStart(2, '0');
            const formattedScheduleDate = `${year}-${month}-${day}`;

            setDisplayData({
                client_name: `${ucwords(data.firstname)} ${ucwords(data.lastname)}`,
                inquiry: data.service || '',
                user_id: `${ucwords(data.AO)}` || '',
                schedule_date: formattedScheduleDate || '',
                schedule_time: data.schedule_time || '',
            });

            setFormData((prev) => ({
                ...prev,
                sched_status: data.sched_status || '',
                client_id: data.client_id || '', 
                email: data.email || '', 
                milestones_id: data.milestones_id || '', 

            }));
        }
    }, [data]);

    const handleSubmit = async (e) => {
        e.preventDefault(); 
    
        const buttonType = e.nativeEvent.submitter.name;
    
        try {
            const token = localStorage.getItem('token');
            let response;
    
            if (buttonType === 'updateSchedule') {
                // Update schedule details
                const scheduleDataToUpdate = {
                    sched_status: formData.sched_status, 
                };
                response = await axios.put(`${API_URL}/schedules/${data.id}`, scheduleDataToUpdate, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            } else if (buttonType === 'updateClient') {
                const clientDataToUpdate = {
                    milestone_id: formData.milestone_id, 
                };
                response = await axios.put(`${API_URL}/consultation/${data.client_id}`, clientDataToUpdate, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
            }
    
            const result = response.data;
    
            onSuccess();
            handleClose();
    
            Swal.fire({
                title: 'Success!',
                text: result.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                window.location.reload(); // Reload page after success
            });
    
        } catch (error) {
            console.error('Error updating:', error);
            Swal.fire({
                title: 'Error!',
                text: 'There was an error processing your request.',
                icon: 'error',
                showConfirmButton: false,
                timer: 3000
            });
        }
    };
    
    
    const handleClose = () => {
        setFormData({
            sched_status: '',
            client_id: '',
            email: '',
            milestone_id: ''
        });
        onClose();
    };

    return (
        <div 
            id="static-modal" 
            className={`fixed overflow-y-auto overflow-x-hidden inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 max-h-full ${isOpen ? 'flex' : 'hidden'}`} 
            data-modal-backdrop="static" 
            tabIndex="-1" 
            aria-hidden={!isOpen}
        >
            <div className="relative p-4 w-full max-w-4xl max-h-full">
                <div className="relative bg-white rounded-lg shadow">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                        <h3 className="text-xl font-semibold text-gray-900" id="modal-title">
                            Schedule
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                            onClick={handleClose}
                        >
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5 space-y-4">
                        <form onSubmit={handleSubmit}>
                            <div className='flex flex-col sm:flex-row gap-5 w-full'>
                                <div className='flex flex-col w-full gap-3'>
                                    <div className="w-full">
                                        <label htmlFor="client_id" className="block font-medium text-gray-700">Client Name</label>
                                        <input
                                            type="text"
                                            id="client_id"
                                            value={displayData.client_name || ''} 
                                            className="bg-gray-200 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-none focus:border-none"
                                            readOnly
                                        />
                                    </div>

                                    <div className="w-full">
                                        <label htmlFor="inquiry" className="block font-medium text-gray-700">Inquiry</label>
                                        <input
                                            type="text"
                                            id="inquiry"
                                            value={displayData.inquiry || ''} 
                                            className="bg-gray-200 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-none focus:border-none"
                                            readOnly
                                        />
                                    </div> 

                                    <div className="w-full mt-2">
                                        <label htmlFor="user_id" className="block font-medium text-gray-700">Account Officer</label>
                                        <input
                                            type="text"
                                            id="user_id"
                                            value={displayData.user_id || ''} 
                                            className="bg-gray-200 block w-full border-gray-300 rounded-lg shadow-sm focus:ring-none focus:border-none"
                                            readOnly
                                        />
                                    </div>

                                    <div className="flex gap-4 mt-2">
                                        <div className="w-1/2">
                                            <label htmlFor="schedule_date" className="block font-medium text-gray-700">Schedule Date</label>
                                            <input
                                                type="date"
                                                id="schedule_date"
                                                name="schedule_date"
                                                value={displayData.schedule_date || ''} 
                                                className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                                required
                                                readOnly
                                            />
                                        </div>

                                        <div className="w-1/2">
                                            <label htmlFor="schedule_time" className="block font-medium text-gray-700">Schedule Time</label>
                                            <input
                                                type="time"
                                                id="schedule_time"
                                                name="schedule_time"
                                                value={displayData.schedule_time || ''} 
                                                className="bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                                required
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full mt-2 mb-5">
                                        <label htmlFor="sched_status" className="block font-medium text-gray-700">Schedule Status</label>
                                        <select
                                            id="sched_status"
                                            value={formData.sched_status}
                                            onChange={(e) => setFormData({ ...formData, sched_status: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                            disabled={formData.sched_status === 3 || formData.sched_status === 4}
                                        >
                                            <option value="1">On-going</option>
                                            <option value="2">In process</option>                                                 
                                            <option value="3">Re-schedule</option>
                                            <option value="4">Cancelled</option>
                                        </select>
                                    </div>
                                </div>
                                {formData.sched_status === 2 && (
                                    <div className='flex flex-col w-full'>
                                        <label htmlFor="milestones" className="block font-medium text-gray-700">Milestones</label>
                                        <select
                                            id="milestones"
                                            value={formData.milestones_id}
                                            onChange={(e) => setFormData({ ...formData, milestone_id: e.target.value })}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                        >
                                            {/* {formData.milestones_id === 0 && ( */}
                                                <option value="">Select Milestones</option>
                                            {/* )} */}
                                            {milestones.map((milestone) => (
                                                <option key={milestone.id} value={milestone.id}>
                                                    {ucwords(milestone.name)} - {milestone.percentage}%
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </div>
                            <div className="flex justify-end  p-2 border-t border-gray-200 rounded-b gap-2">
                                {(formData.sched_status !== 3 && formData.sched_status !== 4) && (
                                    <button
                                        name='updateSchedule'
                                        type="submit"
                                        className="bg-green-500 hover:bg-green-600 text-white rounded-lg px-4 py-2"
                                    >
                                        Update Schedule
                                    </button>
                                )}

                                {formData.sched_status === 2 && (

                                    <button
                                        name='updateClient'
                                        type="submit"
                                        className="bg-red-500 hover:bg-red-600 text-white rounded-lg px-4 py-2"
                                    >
                                        Update Client
                                    </button>
                                )}

                                <button
                                    type="button"
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-900 rounded-lg px-4 py-2"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditScheduleModal;
