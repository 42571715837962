import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ucwords } from '../../utils/Ucwords';

import axios from 'axios';
import Swal from 'sweetalert2';
import TopBar from '../../components/admin/TopBar';
import CreateMilestoneModal from '../../components/admin/CreateMilestoneModal';
import DataTable from 'react-data-table-component';
import EditMilestoneModal from '../../components/admin/EditMilestoneModal';
import { useAuth } from '../../context/AuthContext';

const MilestonePage = () => {
    const API_URL = process.env.REACT_APP_API_URL;

    const { id } = useParams();
    const [data, setData] = useState(null);
    const [milestones, setMilestones] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMilestone, setSelectedMilestone] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const { user } = useAuth();

    const openCreateModal = () => setIsCreateModalOpen(true);
    const closeCreateModal = () => setIsCreateModalOpen(false);

    const openEditModal = (milestone) => {
        setSelectedMilestone(milestone);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedMilestone(null);
        setIsEditModalOpen(false);
    };

    const fetchService = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.get(`${API_URL}/milestones/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setData(response.data);
        } catch (error) {
            console.error('Error fetching milestones:', error);
        }
    };

    const fetchMilestone = async () => {
        const token = localStorage.getItem('token');
        console.log("Service ID:", id); // Log to see the ID being used
        try {
            const response = await axios.get(`${API_URL}/milestone/${id}`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log("Fetched milestones:", response.data);
            setMilestones(response.data);
        } catch (error) {
            console.error('Error fetching milestones:', error);
        }
    };
    
    useEffect(() => {
        fetchService();
        fetchMilestone();
    }, [id]);

    const handleEdit = (milestone) => {
        openEditModal(milestone);
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });
    
        if (result.isConfirmed) {
            try {
                const token = localStorage.getItem('token'); // Retrieve token from local storage
                const deleteMilestone = await axios.delete(`${API_URL}/milestones/${id}`, {
                    headers: {
                      'Authorization': `Bearer ${token}` // Attach token to headers
                    }
                });
                const { message } = deleteMilestone.data;
                fetchMilestone();

                // Show success message
                Swal.fire({
                    title: 'Deleted!',
                    text: message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
            } catch (error) {
                console.error('Error deleting milestone:', error);
                Swal.fire(
                    'Error!',
                    'There was an error deleting the milestone.',
                    'error'
                );
            }
        }
    };
    
    // Filter milestones based on searchTerm
    const filterMilestone = milestones.filter(milestone => 
        milestone.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = [
        {
            name: 'Name',
            selector: row => `${ucwords(row.name)}`,
            sortable: true,
        },
        {
            name: 'Percentage',
            selector: row => `${row.percentage}%`,
            sortable: true,
        },
        ...(user.role_name === 'Super Admin' ? [
            {
                name: 'Action',
                cell: row => (
                    <div className="flex space-x-2">
                        <button
                            className="text-blue-500 hover:text-blue-700"
                            onClick={() => handleEdit(row)}
                        >
                            <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button
                            className="text-red-500 hover:text-red-700"
                            onClick={() => handleDelete(row.id)}
                        >
                            <i className="fa-solid fa-trash"></i>
                        </button>
                    </div>
                )
            }
        ] : [])
    ];

    const customStyles = {
        headCells: { style: { fontWeight: 'bold', fontSize: '14px' } },
    };

    return (
        <>
            <TopBar />
            <CreateMilestoneModal  isOpen={isCreateModalOpen} onClose={closeCreateModal} serviceId={id} /> 
            <EditMilestoneModal 
                isOpen={isEditModalOpen} 
                onClose={closeEditModal} 
                onSuccess={fetchMilestone} 
                milestone={selectedMilestone} 
            />

            <div className="p-2 mt-16">
                <div className="flex flex-col">
                    <div className="flex pb-2 justify-between border-b-2">
                        <div className="font-semibold text-xl">
                            <label>{data ? data.service : 'Service not found'}</label>
                        </div>
                        {user.role_name === 'Super Admin' && (
                            <button 
                                className="text-white bg-[var(--customRed)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                onClick={openCreateModal}
                            >
                                Create Milestone
                            </button>
                        )}
                    </div>
                </div>
                <div className='mt-3'>
                    <div className='flex justify-end gap-3'>
                        <label htmlFor='search' className='mt-1.5'>Search:</label>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="form-input p-1.5 block border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800 mb-1"
                        />
                    </div>
                    <DataTable
                        columns={columns}
                        data={filterMilestone}
                        pagination
                        responsive
                        highlightOnHover
                        pointerOnHover
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        customStyles={customStyles}
                    />
                </div>
            </div>
        </>
    );
};

export default MilestonePage;
