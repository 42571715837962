import React, { useState, useEffect } from 'react';
import TopBar from '../../components/admin/TopBar';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { ucwords } from '../../utils/Ucwords';
import { date, DateFormatter } from '../../utils/DateFormatter';

const AdminDashboardPage = () => {
  const API_URL = process.env.REACT_APP_API_URL;

  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const fetchClients = async () => {
    try {
        const token = localStorage.getItem('token'); // Retrieve token from local storage
        const response = await axios.get(`${API_URL}/consultation`, {
          headers: {
            'Authorization': `Bearer ${token}` // Attach token to headers
          }
        });
        setClients(response.data);
    } catch (error) { 
        console.error('Error fetching clients:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const filteredClients = clients.filter(clients => {
    return (
      `${ucwords(clients.firstname)} ${ucwords(clients.lastname)}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
      clients.email.toLowerCase().includes(searchTerm.toLowerCase()) 
    );
  });

  const columns = [
    {
      name: 'Full Name',
      selector: row => `${ucwords(row.firstname)} ${ucwords(row.lastname)}`,
      sortable: true,
    },
    {
      name: 'Email Address',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Inquiries',
      selector: row => row.service,
      sortable: true,
    },
    {
      name: 'Date & Time',
      selector: row => `${DateFormatter(row.created_at)}`,
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
        style: {
            fontWeight: 'bold',
            fontSize: '14px'
        },
    },
  };

  return (
    <>
      <TopBar />
      <div className="p-2 mt-16">
        <div className="flex flex-col">
          <div className="flex pb-2 border-b-2">
            <div className="font-semibold text-xl">
              <label htmlFor="">List of Inquiries</label>
            </div>
          </div>
          <div className='mt-5'>
            <div className='flex justify-end gap-3'>
              <label htmlFor='search' className='mt-1.5'>Search:</label>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-input p-1.5 block border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800 mb-1"                            
              />
            </div>
            <div className="relative overflow-x-auto">
              <DataTable
                columns={columns}
                data={filteredClients}
                pagination
                responsive
                highlightOnHover
                pointerOnHover
                paginationPerPage={10}
                paginationRowsPerPageOptions={[5, 10, 15, 20]}
                customStyles={customStyles}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboardPage;
