import React, { useState } from 'react';
import Swal from 'sweetalert2';

const CreateHiringModal = ({ isOpen, onClose, onSuccess }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        position: '',
        branch: '',
        description: '',
        responsibilities: '',
        qualifications: '',
        hiring_status: '' 
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: '',
        }));
    };
    
    const validateForm = () => {
        const newErrors = {};
        if (!formData.position) {
            newErrors.position = 'Please enter position';
        }
        if (!formData.branch) {
            newErrors.branch = 'Please enter branch';
        }
        if (!formData.description) {
            newErrors.description = 'Please enter description';
        }
        if (!formData.responsibilities) {
            newErrors.responsibilities = 'Please enter responsibilities';
        }
        if (!formData.qualifications) {
            newErrors.qualifications = 'Please enter qualifications';
        }
        if (!formData.hiring_status) {
            newErrors.hiring_status = 'Please select status';
        }
        return newErrors;
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        try {
            const token = localStorage.getItem('token'); // Ensure this token is correct and valid
            const response = await fetch(`${API_URL}/hirings`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Include the token if required by the API
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Unknown error');
            }

            const result = await response.json();
            console.log(result);

            // Call the onSuccess callback to refresh the employee list
            if (onSuccess) onSuccess();

            // Reset form data and close modal
            setFormData({
                position: '',
                branch: '',
                description: '',
                responsibilities: '',
                qualifications: '',
                hiring_status: '' 
            });
            onClose();

            Swal.fire({
                title: 'Success!',
                text: result.message,
                icon: 'success',
                showConfirmButton: false,
                timer: 3000
            }).then(() => {
                window.location.reload();
            });
            
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleClose = () => {
        // Reset form data
        setFormData({
            position: '',
            branch: '',
            description: '',
            responsibilities: '',
            qualifications: '',
            hiring_status: '' 
        });
        // Call the passed onClose function
        onClose();
    };

    return (
        <>
            <div id="static-modal" className={`fixed inset-0 z-50 flex overflow-y-auto items-center justify-center bg-black bg-opacity-50 ${isOpen ? 'flex' : 'hidden'}`} data-modal-backdrop="static" tabIndex="-1" aria-hidden="true">
                <div className="relative p-4 w-full max-w-2xl max-h-full">
                    <div className="relative bg-white rounded-lg shadow">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                            <h3 className="text-xl font-semibold text-gray-900">
                                Create Hiring Modal
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                                onClick={handleClose}
                            >
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5 space-y-4 ">
                            <form onSubmit={handleSubmit}>
                                <div className='flex flex-row gap-5 w-full'>
                                    <div className="mb-4 w-full">
                                        <label htmlFor="position" className="block text-sm font-medium text-gray-700">Position</label>
                                        <input 
                                            type="text" 
                                            id="position" 
                                            value={formData.position} 
                                            onChange={handleChange} 
                                            className="bg-gray-50 border border-gray-300 text-green-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5" 
                                        />
                                        {errors.position && (
                                            <p className="text-red-600">{errors.position}</p>
                                        )}
                                    </div>
                                    <div className="mb-4 w-full">
                                        <label htmlFor="branch" className="block text-sm font-medium text-gray-700">Branch</label>
                                        <input 
                                            type="text" 
                                            id="branch" 
                                            value={formData.branch} 
                                            onChange={handleChange} 
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5" 
                                        />
                                        {errors.branch && (
                                            <p className="text-red-600">{errors.branch}</p>
                                        )}
                                    </div>  
                                </div>  
                                <div className="mb-4 w-full">
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                                    <input 
                                        type="text" 
                                        id="description" 
                                        value={formData.description} 
                                        onChange={handleChange} 
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5" 
                                    />
                                    {errors.description && (
                                        <p className="text-red-600">{errors.description}</p>
                                    )}
                                </div>
                                <div className="w-full">
                                    <label htmlFor="responsibilities" className="block py-2 font-medium text-gray-700">Responsibilities</label>
                                    <textarea
                                        id="responsibilities"
                                        value={formData.responsibilities}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                        rows="3"
                                        placeholder='-Sample
                                        -Sample
                                        -Sample'
                                    ></textarea>

                                    {errors.responsibilities && (
                                        <p className="text-red-600">{errors.responsibilities}</p>
                                    )}
                                </div>  
                                <div className="w-full">
                                    <label htmlFor="qualifications" className="block py-2 font-medium text-gray-700">Qualifications</label>
                                    <textarea
                                        id="qualifications"
                                        value={formData.qualifications}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                        rows="3"
                                        placeholder='-Sample
                                        -Sample
                                        -Sample'
                                    ></textarea>

                                    {errors.qualifications && (
                                        <p className="text-red-600">{errors.qualifications}</p>
                                    )}
                                </div>  
                                
                                <div className="w-full mb-4">
                                    <label htmlFor="hiring_status" className="block font-medium text-gray-700">Status</label>
                                    <select
                                        id="hiring_status"
                                        value={formData.hiring_status}
                                        onChange={handleChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                                    >
                                        <option selected>Select a Status</option>
                                        <option value="On-going">On-going</option>
                                        <option value="Completed">Completed</option>
                                    </select>
                                    {errors.hiring_status && (
                                        <p className="text-red-600">{errors.hiring_status}</p>
                                    )}
                                </div>

                                <div className="flex items-center p-2 md:p-2 border-t border-gray-200 rounded-b">
                                    <button type="submit" className="text-white bg-[var(--customRed)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save</button>
                                    <button type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-red-700 focus:z-10 focus:ring-4 focus:ring-gray-100" onClick={handleClose}>Close</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateHiringModal;
