import React, { useState, useEffect } from 'react';
import TopBar from '../../components/admin/TopBar';
import CreateEmployeeModal from '../../components/admin/CreateEmployeeModal';
import EditEmployeeModal from '../../components/admin/EditEmployeeModal';
import axios from 'axios';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { ucwords } from '../../utils/Ucwords';
import AssignRoleModal from '../../components/admin/AssignRoleModal';
import { useAuth } from '../../context/AuthContext';

const EmployeePage = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isAssignRoleModalOpen, setIsAssignRoleModalOpen] = useState(false);
    const [employees, setEmployees] = useState([]);
    
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [selectedEmployeeRole, setSelectedEmployeeRole] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const { user } = useAuth();

    const openCreateModal = () => setIsCreateModalOpen(true);
    const closeCreateModal = () => setIsCreateModalOpen(false);

    const openEditModal = (employee) => {
        setSelectedEmployee(employee);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setSelectedEmployee(null);
        setIsEditModalOpen(false);
    };

    const openAssignRoleModal = (employee) => {
        setSelectedEmployeeRole(employee);
        setIsAssignRoleModalOpen(true);
    };

    const closeAssignRoleModal = () => {
        setSelectedEmployeeRole(null);
        setIsAssignRoleModalOpen(false);
    };

    const fetchEmployees = async () => {
        const token = localStorage.getItem('token'); // Or however you're storing your token
        try {
            const response = await axios.get(`${API_URL}/employees`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setEmployees(response.data);
        } catch (error) { 
            console.error('Error fetching employees:', error);
        }
    };

    useEffect(() => {
        fetchEmployees();
    }, []);

    const handleEdit = (employee) => {
        openEditModal(employee);
    };

    const handleAssignRole = (employee) => {
        openAssignRoleModal(employee);
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel'
        });
    
        if (result.isConfirmed) {
            try {
                const token = localStorage.getItem('token'); // Retrieve token from local storage
                const deleteEmployee = await axios.delete(`${API_URL}/employees/${id}`, {
                    headers: {
                      'Authorization': `Bearer ${token}` // Attach token to headers
                    }
                });
                const { message } = deleteEmployee.data;
                fetchEmployees();

                // Show success message
                Swal.fire({
                    title: 'Deleted!',
                    text: message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                });
            } catch (error) {
                console.error('Error deleting employee:', error);
                Swal.fire(
                    'Error!',
                    'There was an error deleting the employee.',
                    'error'
                );
            }
        }
    };

    const handleActivate = async (id) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "Do you want to activate this account?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#008000",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Activate"
        });
    
        if (result.isConfirmed) {
            try {
                const token = localStorage.getItem('token'); 
                const activateEmployee = await axios.put(
                    `${API_URL}/user_status/${id}`, 
                    { status: 1 },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                
                const { message } = activateEmployee.data;
                fetchEmployees();

                // Show success message
                Swal.fire({
                    title: 'Success!',
                    text: message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                }).then((result) => {
                    window.location.reload();
                });
            } catch (error) {
                console.error('Error deleting employee:', error);
                Swal.fire(
                    'Error!',
                    'There was an error deleting the employee.',
                    'error'
                );
            }
        }
    };

    const handleDeactivation = async (id) => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "Do you want to de-activate this account?",
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: "#008000",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, De-activate"
        });
    
        if (result.isConfirmed) {
            try {
                const token = localStorage.getItem('token'); 
                const activateEmployee = await axios.put(
                    `${API_URL}/user_status/${id}`, 
                    { status: 2 },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );
                
                const { message } = activateEmployee.data;
                fetchEmployees();

                // Show success message
                Swal.fire({
                    title: 'Success!',
                    text: message,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 3000
                }).then((result) => {
                    window.location.reload();
                });
            } catch (error) {
                console.error('Error deleting employee:', error);
                Swal.fire(
                    'Error!',
                    'There was an error deleting the employee.',
                    'error'
                );
            }
        }
    };

    const filteredEmployees = employees.filter(employee => {
        return (
            `${ucwords(employee.firstname)} ${ucwords(employee.lastname)}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
            employee.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
            employee.phone.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });

    const columns = [
        {
            name: 'Full Name',
            selector: row => `${ucwords(row.firstname)} ${ucwords(row.lastname)}`,
            sortable: true,
        },
        {
            name: 'Email Address',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Contact Number',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => (
                row.status === 0 ? <span className="text-yellow-600">Inactive</span> : 
                row.status === 1 ? <span className="text-green-600">Active</span> : 
                <span className="text-orange-600">Deactivated</span>
            ),
            sortable: true,
        },
        {
            name: 'Level',
            selector: row => row.role_name,
            sortable: true,
        },
        ...(user.role_name === 'Super Admin' ? [
            {
                name: 'Action',
                cell: row => (
                    <div className="flex space-x-2">
                        <button
                            className="text-blue-500 hover:text-blue-700"
                            onClick={() => handleEdit(row)}
                        >
                            <i className="fa-solid fa-pen-to-square"></i>
                        </button>
                        <button
                            className="text-red-500 hover:text-red-700"
                            onClick={() => handleDelete(row.id)}
                        >
                            <i className="fa-solid fa-trash"></i>
                        </button>
                        {row.status === 0 || row.status === 2 ? (
                            <button
                                className="text-green-500 hover:text-green-700"
                                onClick={() => handleActivate(row.id)}
                            >
                                <i className="fa-regular fa-circle-check"></i>
                            </button>
                        ) : (
                            <button
                                className="text-orange-500 hover:text-orange-700"
                                onClick={() => handleDeactivation(row.id)} 
                            >
                                <i className="fa-regular fa-circle-xmark"></i>
                            </button>
                        )}
                        <button
                            className="text-yellow-500 hover:text-yellow-700"
                            onClick={() => handleAssignRole(row.id)}
                        >
                            <i className="fa-solid fa-user-shield"></i>                    
                        </button>
                    </div>
                )
            }
        ] : [])
    ];

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
    };

    return (
        <>
            <TopBar />
            <CreateEmployeeModal isOpen={isCreateModalOpen} onClose={closeCreateModal} onSuccess={fetchEmployees} />
            <EditEmployeeModal isOpen={isEditModalOpen} onClose={closeEditModal} onSuccess={fetchEmployees} employee={selectedEmployee} />
            <AssignRoleModal isOpen={isAssignRoleModalOpen} onClose={closeAssignRoleModal} onSuccess={fetchEmployees} employee={selectedEmployeeRole} />
            <div className="p-2 mt-16">
                <div className="flex flex-col">
                    <div className="flex pb-2 justify-between border-b-2">
                        <div className="font-semibold text-xl">
                                <label htmlFor="">List of Employees</label>
                        </div>
                        {user.role_name === 'Super Admin' && (
                            <button
                                onClick={openCreateModal}
                                className="block text-white bg-[var(--customRed)] hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                type="button"
                            >
                                Create Employee
                            </button>
                        )}
                    </div>
                    <div className='mt-3'>
                        <div className='flex justify-end gap-3'>
                            <label htmlFor='search' className='mt-1.5'>Search:</label>
                            <input
                                type="text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-input p-1.5 block border-gray-300 rounded-lg shadow-sm focus:ring-green-800 focus:border-green-800 mb-1"                            
                            />
                        </div>
                        <DataTable
                            columns={columns}
                            data={filteredEmployees}
                            pagination
                            responsive
                            highlightOnHover
                            pointerOnHover
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[5, 10, 15, 20]}
                            customStyles={customStyles}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployeePage;
